import Geo from "react-geocode";
import shortId from "shortid";
// https://rest.hospitallcare.com/
// export const BASE_URL_API = "https://www.clinicall.hospitallcare.com/api/";
//export const BASE_URL_API = "https://rest.prod.clinicall.com.pk/api/";
export const BASE_URL_API = "https://careall360Apis.careall360.com/api/";

Geo.setApiKey("AIzaSyBg2TOxjWq4FyCds1YKqS1kt331A4h8ip0");

// set response language. Defaults to english.
Geo.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
// Geocode.setRegion("es");

// Enable or disable logs. Its optional.
Geo.enableDebug();

export const Geocode = Geo;

export const MARITALSTATUS = [
  {
    value: "",
    label: "Select Marital Status",
  },
  {
    value: "unmarried",
    label: "Unmarried",
  },
  {
    value: "married",
    label: "Married",
  },
  {
    value: "divorced",
    label: "Divorced",
  },
  {
    value: "widow",
    label: "Widow",
  },
];

export const PATIENTSTATUS = [
  {
    value: "",
    label: "Select Patient Status",
  },
  {
    value: "entitled",
    label: "Entitled",
  },
  {
    value: "non-titled",
    label: "Non Titled",
  },
  {
    value: "family",
    label: "Family",
  },

];

export const ORGANIZATION = [
  {
    value: "",
    label: "Select organization",
  },
  {
    value: "akhuwat-Sod",
    label: "Akhuwat-Sod",
  },
  {
    value: "akhuwat-Sha",
    label: "Akhuwat-Sha",
  },
  {
    value: "Akhuwat-Thc",
    label: "Akhuwat-THC",
  },
  {
    value: "Tele",
    label: "Tele",
  },
  {
    value: "Netsol",
    label: "Netsol",
  },
  {
    value: "Hospitall",
    label: "Hospitall",
  },
];

export const FEE_FREQUENCY = [
  {
    value: "",
    label: "Select Fee Frequency",
  },
  {
    value: "once",
    label: "Once",
  },
  {
    value: "weeks",
    label: "Weekly",
    isDisabled: true,
  },
  {
    value: "months",
    label: "Monthly",
    isDisabled: true,
  },
];

export const FIX_PERCENTAGE_FEE = [
  {
    value: "",
    label: "Select Type",
  },
  {
    value: "F",
    label: "Fixed",
  },
  {
    value: "P",
    label: "Percentage",
  },
];

export const DOCTOR_SETTLEMENT_FREQUENCY = [
  {
    value: "",
    label: "Select Type",
  },
  {
    value: "week",
    label: "Weekly",
  },
  {
    value: "biweek",
    label: "Biweekly",
  },
  {
    value: "month",
    label: "Monthly",
  },
];

export const BLOODGROUPS = [
  // {
  //   value: "",
  //   label: "Select Blood Group",
  // },
  {
    value: "na",
    label: "N/A",
  },
  {
    value: "a+",
    label: "A+",
  },
  {
    value: "a-",
    label: "A-",
  },
  {
    value: "b+",
    label: "B+",
  },
  {
    value: "b-",
    label: "B-",
  },
  {
    value: "ab+",
    label: "AB+",
  },
  {
    value: "ab-",
    label: "AB-",
  },
  {
    value: "o+",
    label: "O+",
  },
  {
    value: "o-",
    label: "O-",
  },
 
];
export const GENDERS = [
  {
    value: "",
    label: "Select Gender",
  },
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "transgender",
    label: "Transgender",
  },
];
export const TITLES = [
  {
    value: "",
    label: "Select Title",
  },
  {
    value: "Dr",
    label: "Dr",
  },
  {
    value: "Prof",
    label: "Prof",
  },
  {
    value: "Asst",
    label: "Asst",
  },
];

export const APPOINTMENT_TYPE = [
  {
    value: "",
    label: "Select Type",
  },
  {
    value: "physical",
    label: "Physical",
  },
  {
    value: "online",
    label: "Online",
  },
];
export const DURATION = [
  {
    value: "",
    label: "Select Duration",
  },
  {
    value: 15,
    label: "15 minute",
  },
  {
    value: 30,
    label: "30 minute",
  },
  {
    value: 45,
    label: "45 minute",
  },
  {
    value: 60,
    label: "60 minute",
  },
];
export const SCHEDULE_HEADING = [
  {
    value: "",
    label: "Select Heading",
  },
  {
    value: "summer",
    label: "Summer",
  },
  {
    value: "winter",
    label: "Winter",
  },
];
export const SCHEDULE_MINIMUM_BOOKING_HOURS = [
  {
    value: "",
    label: "Select Hour",
  },
  {
    value: 1,
    label: "1 Hour",
  },
  {
    value: 2,
    label: "2 Hour",
  },
  {
    value: 3,
    label: "3 Hour",
  },
  {
    value: 4,
    label: "4 Hour",
  },
  {
    value: 5,
    label: "5 Hour",
  },
  {
    value: 6,
    label: "6 Hour",
  },
  {
    value: 7,
    label: "7 Hour",
  },
  {
    value: 8,
    label: "8 Hour",
  },
  {
    value: 9,
    label: "9 Hour",
  },
  {
    value: 10,
    label: "10 Hour",
  },
  {
    value: 11,
    label: "11 Hour",
  },
  {
    value: 12,
    label: "12 Hour",
  },
  {
    value: 24,
    label: "24 Hour",
  },
];

export const HOURS_SLOTS = [
  {
    value: "00:00",
    label: "12 AM",
  },
  {
    value: "1:00",
    label: "1 AM",
  },
  {
    value: "2:00",
    label: "2 AM",
  },
  {
    value: "3:00",
    label: "3 AM",
  },
  {
    value: "4:00",
    label: "4 AM",
  },
  {
    value: "5:00",
    label: "5 AM",
  },
  {
    value: "6:00",
    label: "6 AM",
  },
  {
    value: "7:00",
    label: "7 AM",
  },
  {
    value: "8:00",
    label: "8 AM",
  },
  {
    value: "9:00",
    label: "9 AM",
  },
  {
    value: "10:00",
    label: "10 AM",
  },
  {
    value: "11:00",
    label: "11 AM",
  },
  {
    value: "12:00",
    label: "12 PM",
  },
  {
    value: "13:00",
    label: "1 PM",
  },
  {
    value: "14:00",
    label: "2 PM",
  },
  {
    value: "15:00",
    label: "3 PM",
  },
  {
    value: "16:00",
    label: "4 PM",
  },
  {
    value: "17:00",
    label: "5 PM",
  },
  {
    value: "18:00",
    label: "6 PM",
  },
  {
    value: "19:00",
    label: "7 PM",
  },
  {
    value: "20:00",
    label: "8 PM",
  },
  {
    value: "21:00",
    label: "9 PM",
  },
  {
    value: "22:00",
    label: "10 PM",
  },
  {
    value: "23:00",
    label: "11 PM",
  },
];
export const MEDICINES_TYPE = [
  {
    value: "",
    label: "Select Medicine Type",
  },
  {
    label: "Anticeptic",
    value: "anticeptic",
  },
  // {
  //   label: "Antibiotic",
  //   value: "antibiotic",
  // },
  {
    value: "balm",
    label: "Balm",
  },

  {
    value: "capsule",
    label: "Capsule",
  },

  {
    value: "cream",
    label: "Cream",
  },
  {
    value: "drops",
    label: "Drops",
  },
  {
    value: "guaze",
    label: "Guaze",
  },
  {
    value: "inhaler",
    label: "Inhaler",
  },
  {
    value: "injection",
    label: "Injection",
  },
  {
    value: "ointment",
    label: "Ointment",
  },
  {
    value: "tablet",
    label: "Tablet",
  },

  {
    value: "topical",
    label: "Topical",
  },
  {
    value: "syrup",
    label: "Syrup",
  },

  {
    label: "Spray",
    value: "spray",
  },
  {
    value: "sach",
    label: "Sach",
  },
  {
    label: "Shampoo",
    value: "shampoo",
  },
  {
    label: "Vaccine",
    value: "vaccine",
  },

  {
    value: "vial",
    label: "Vial",
  },

  {
    value: "patch",
    label: "Patch",
  },
];
export const APPOINTMENT_STATUS = [
  { value: "", label: "Select Status" },
  { value: "pending", label: "Pending" },
  { value: "approved", label: "Approved" },
  { value: "done", label: "Done" },
  { value: "ongoing", label: "Ongoing" },
  { value: "follow_up", label: "Follow Up" },
  { value: "reschedule", label: "Reschedule" },
  { value: "refund", label: "Refund" },
  { value: "auto_cancel", label: "Auto Cancel" },
  { value: "cancel_by_doctor", label: "Cancelled by doctor" },
  { value: "cancel_by_patient", label: "Cancelled by patient" },
  { value: "no_show", label: "No Show" },
];

export const PAID_STATUS = [
  { value: "", label: "Select Paid Status" },
  { value: "paid", label: "Paid" },
  { value: "unpaid", label: "Unpaid" },
];

export const ACTIVE_STATUS = [
  { value: "", label: "Select Status" },
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

export const FILTERARRAY = [
  {
    type: "text",
    value: "",
    show: [
      //appointment input filters
      {
        id: shortId.generate(),
        module: "appointment",
        name: "patient_name",
        placeholder: "Patient Name",
        label: "Patient Name",
      },
      {
        id: shortId.generate(),
        module: "appointment",
        name: "doctor_name",
        placeholder: "Doctor Name",
        label: "Doctor Name",
      },
      {
        id: shortId.generate(),
        module: "appointment",
        name: "center_name",
        placeholder: "Center",
        label: "Center",
      },
      {
        id: shortId.generate(),
        module: "appointment",
        name: "treatment_name",
        placeholder: "Treatment",
        label: "Treatment",
      },
      {
        id: shortId.generate(),
        module: "appointment",
        name: "reference_no",
        placeholder: "Reference no",
        label: "Reference no",
      },
      //organization input filters
      {
        id: shortId.generate(),
        module: "organization",
        name: "name",
        placeholder: "Name",
        label: "Name",
      },
      {
        id: shortId.generate(),
        module: "organization",
        name: "phone",
        placeholder: "Phone",
        label: "Phone",
      },
      {
        id: shortId.generate(),
        module: "organization",
        name: "email",
        placeholder: "Email",
        label: "Email",
      },
      //doctor input filters
      {
        id: shortId.generate(),
        module: "doctor",
        name: "full_name",
        placeholder: "Name",
        label: "Name",
      },
      {
        id: shortId.generate(),
        module: "doctor",
        name: "phone",
        placeholder: "Phone",
        label: "Phone",
      },
      {
        id: shortId.generate(),
        module: "doctor",
        name: "email",
        placeholder: "Email",
        label: "Email",
      },
      {
        id: shortId.generate(),
        module: "doctor",
        name: "pmdc",
        placeholder: "PMDC",
        label: "PMDC",
      },
      {
        id: shortId.generate(),
        module: "doctor",
        name: "speciality",
        placeholder: "Speciality",
        label: "Speciality",
      },
      //patient input filters
      {
        id: shortId.generate(),
        module: "patient",
        name: "name",
        placeholder: "Name",
        label: "Name",
      },
      {
        id: shortId.generate(),
        module: "patient",
        name: "phone",
        placeholder: "Phone",
        label: "Phone",
      },
      {
        id: shortId.generate(),
        module: "patient",
        name: "cnic",
        placeholder: "CNIC",
        label: "CNIC",
      },
      {
        id: shortId.generate(),
        module: "patient",
        name: "patientId",
        placeholder: "Family CNIC",
        label: "Family CNIC",
      },
      {
        id: shortId.generate(),
        module: "patient",
        name: "email",
        placeholder: "Email",
        label: "Email",
      },
      {
        id: shortId.generate(),
        module: "patient",
        name: "doctor_name",
        placeholder: "Doctor",
        label: "Doctor",
      },
      //bank account input filters
      {
        id: shortId.generate(),
        module: "bank_account",
        name: "bank_name",
        placeholder: "Bank Name",
        label: "Bank Name",
      },
      {
        id: shortId.generate(),
        module: "bank_account",
        name: "account_number",
        placeholder: "Account Number",
        label: "Account Number",
      },
      {
        id: shortId.generate(),
        module: "bank_account",
        name: "branch_code",
        placeholder: "Branch Code",
        label: "Branch Code",
      },
      {
        id: shortId.generate(),
        module: "bank_account",
        name: "iban",
        placeholder: "IBAN",
        label: "IBAN",
      },
      //diagnostic input filter
      {
        id: shortId.generate(),
        module: "diagnostic",
        name: "name",
        placeholder: "Name",
        label: "Name",
      },
      //medicine input filter
      {
        id: shortId.generate(),
        module: "medicine",
        name: "name",
        placeholder: "Name",
        label: "Name",
      },
      //partnership input filter
      {
        id: shortId.generate(),
        module: "partnership",
        name: "doctor_name",
        placeholder: "Doctor",
        label: "Doctor",
      },
      {
        id: shortId.generate(),
        module: "partnership",
        name: "plan_name",
        placeholder: "Plan",
        label: "Plan",
      },
      //permission input filter
      {
        id: shortId.generate(),
        module: "permission",
        name: "title",
        placeholder: "Title",
        label: "Title",
      },
      {
        id: shortId.generate(),
        module: "permission",
        name: "description",
        placeholder: "Code",
        label: "Code",
      },
      {
        id: shortId.generate(),
        module: "permission",
        name: "url",
        placeholder: "URL",
        label: "URL",
      },
      //plan input filter
      {
        id: shortId.generate(),
        module: "plan",
        name: "category_name",
        placeholder: "Name",
        label: "Name",
      },
      {
        id: shortId.generate(),
        module: "plan",
        name: "fee",
        placeholder: "Fee",
        label: "Fee",
      },
      {
        id: shortId.generate(),
        module: "plan",
        name: "fee_grace_days",
        placeholder: "Grace Days",
        label: "Grace Days",
      },
      //treatment input filter
      {
        id: shortId.generate(),
        module: "treatment",
        name: "name",
        placeholder: "Name",
        label: "Name",
      },
      //user input filter
      {
        id: shortId.generate(),
        module: "user",
        name: "name",
        placeholder: "Name",
        label: "Name",
      },
      {
        id: shortId.generate(),
        module: "user",
        name: "username",
        placeholder: "Username",
        label: "Username",
      },
      {
        id: shortId.generate(),
        module: "user",
        name: "email",
        placeholder: "Email",
        label: "Email",
      },
      //doctor payments filter
      {
        id: shortId.generate(),
        module: "doctor_payments",
        name: "doctor_name",
        placeholder: "Doctor",
        label: "Doctor",
      },
      // //refund appointmens filter
      // {
      //   id: shortId.generate(),
      //   module: "refund_appointment",
      //   name: "refe",
      //   placeholder: "Doctor",
      //   label: "Doctor",
      // },
    ],
  },
  {
    type: "select",
    show: [
      //appointment select filters
      {
        id: shortId.generate(),
        module: "appointment",
        value: { value: "", label: "Select Paid Status" },
        name: "paid_status",
        placeholder: "Please select Paid Status",
        label: "Paid Status",
        options: [...PAID_STATUS],
      },
      {
        id: shortId.generate(),
        module: "appointment",
        value: { value: "", label: "Select Status" },
        name: "status",
        placeholder: "Please select Status",
        label: "Status",
        options: [...APPOINTMENT_STATUS],
      },
      {
        id: shortId.generate(),
        module: "appointment",
        value: { value: "", label: "Select Type" },
        name: "appointment_type",
        placeholder: "Please select Type",
        label: "Type",
        options: [...APPOINTMENT_TYPE],
      },
      //doctor select filters
      {
        id: shortId.generate(),
        module: "doctor",
        value: { value: "", label: "Select Status" },
        name: "is_active",
        placeholder: "Please select Status",
        label: "Status",
        options: [...ACTIVE_STATUS],
      },
      {
        id: shortId.generate(),
        module: "doctor",
        value: { value: "", label: "Select Gender" },
        name: "gender",
        placeholder: "Please select Gender",
        label: "Gender",
        options: GENDERS,
      },
      //patient select filters
      {
        id: shortId.generate(),
        module: "patient",
        value: { value: "", label: "Select Gender" },
        name: "gender",
        placeholder: "Please select Gender",
        label: "Gender",
        options: GENDERS,
      },
      {
        id: shortId.generate(),
        module: "patient",
        value: { value: "", label: "Select Blood Group" },
        name: "blood_group",
        placeholder: "Please select BLood Group",
        label: "Blood Group",
        options: BLOODGROUPS,
      },
      {
        id: shortId.generate(),
        module: "patient",
        value: { value: "", label: "Select organization" },
        name: "organization",
        placeholder: "Please select organization",
        label: "Organization",
        options: ORGANIZATION,
      },
      {
        id: shortId.generate(),
        module: "patient",
        value: { value: "", label: "Select Marital Status" },
        name: "marital_status",
        placeholder: "Please select Marital Status",
        label: "Marital Status",
        options: MARITALSTATUS,
      },
      //dianostic select filters
      {
        id: shortId.generate(),
        module: "diagnostic",
        value: { value: "", label: "Select Status" },
        name: "is_active",
        placeholder: "Please select Status",
        label: "Status",
        options: [...ACTIVE_STATUS],
      },
      //medicine select filters
      {
        id: shortId.generate(),
        module: "medicine",
        value: { value: "", label: "Select Type" },
        name: "type",
        placeholder: "Please select Type",
        label: "Type",
        options: MEDICINES_TYPE,
      },
      {
        id: shortId.generate(),
        module: "medicine",
        value: { value: "", label: "Select Status" },
        name: "is_active",
        placeholder: "Please select Status",
        label: "Status",
        options: [...ACTIVE_STATUS],
      },
      //permission select filters
      {
        id: shortId.generate(),
        module: "permission",
        value: { value: "", label: "Select Status" },
        name: "is_active",
        placeholder: "Please select Status",
        label: "Status",
        options: [...ACTIVE_STATUS],
      },
      //plan select filters
      {
        id: shortId.generate(),
        module: "plan",
        value: { value: "", label: "Select Type" },
        name: "fix_percentage_fee",
        placeholder: "Please select Type",
        label: "Type",
        options: FIX_PERCENTAGE_FEE,
      },
      //treatment select filters
      {
        id: shortId.generate(),
        module: "treatment",
        value: { value: "", label: "Select Status" },
        name: "is_active",
        placeholder: "Please select Status",
        label: "Status",
        options: [...ACTIVE_STATUS],
      },
      //doctor payment select filters
      {
        id: shortId.generate(),
        module: "doctor_payments",
        value: { value: "", label: "Select Status" },
        name: "paid",
        placeholder: "Please select Status",
        label: "Status",
        options: [
          { value: "", label: "Select Status" },
          { value: "in_progress", label: "Unpaid" },
          { value: "completed", label: "Paid" },
        ],
      },
      {
        id: shortId.generate(),
        module: "doctor_payments",
        value: { value: "", label: "Select Payment Method" },
        name: "payment_method",
        placeholder: "Please select Payment Method",
        label: "Payment Method",
        options: [
          { value: "", label: "Select Payment Method" },
          { value: "cash", label: "Cash" },
          { value: "bank", label: "Bank" },
          { value: "cheque", label: "Cheque" },
          { value: "wallet", label: "Wallet" },
        ],
      },
    ],
  },
  {
    type: "date",
    value: "",
    show: [
      //appointment date input
      {
        id: shortId.generate(),
        module: "appointment",
        name: "date_from",
        label: "Date From",
      },
      {
        id: shortId.generate(),
        module: "appointment",
        name: "date_to",
        label: "Date To",
      },
      //partnership date input
      {
        id: shortId.generate(),
        module: "partnership",
        name: "date_from",
        label: "Date From",
      },
      {
        id: shortId.generate(),
        module: "partnership",
        name: "date_to",
        label: "Date To",
      },

       //Patient date input
      {
        id: shortId.generate(),
        module: "patient",
        name: "date_from",
        label: "Date From",
      },
      {
        id: shortId.generate(),
        module: "patient",
        name: "date_to",
        label: "Date To",
      },


    ],
  },
];
